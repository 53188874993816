@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/prismjs/themes/prism-coy.css';
@import '../node_modules/@fullcalendar/core/main.min.css';
@import '../node_modules/@fullcalendar/daygrid/main.min.css';
@import '../node_modules/@fullcalendar/timegrid/main.min.css';
@import '/src/constants.scss';
@import '../src/assets/CKEditor5/styles.css';

body {
    background-color: $museum-primary-color;
}

:host ::ng-deep {
    .p-dialog-header,
    .p-dialog-content,
    .p-dialog-footer {
        background-color: $museum-secondary-color;
    }
}

.disable-padding {
    padding: 0 0 0 0 !important;
}

.p-accordion p-accordiontab .p-accordion-tab {
    margin-bottom: 8px;
    background-color: #fae7cc !important;
}

.p-accordion .p-accordion-tab.p-accordion-tab-active {
    margin-bottom: 8px;
}

.p-colorpicker-preview,
.p-fluid .p-colorpicker-preview.p-inputtext {
    height: 39px;
    width: 100%;
    border: 1px solid black;
}

.p-accordion-header-link {
    background-color: #fae7cc !important;
}

.p-accordion-content {
    background-color: #fae7cc !important;
}

// Boja obicnog buttona
.p-button {
    background-color: $button-color !important;
}

// Boja success buttona
.p-button-success {
    background-color: #6cc15a !important;
}

// Boja danger buttona
.p-button-danger {
    background-color: #fe737c !important;
}

// Boja warning buttona
.p-button-warning {
    background-color: #f9d66b !important;
}

// Boja help buttona
.p-button-help {
    background-color: #b197aa !important;
}

.p-dialog-header,
.p-dialog-content,
.p-dialog-footer {
    background-color: #fae7cc !important;
}
